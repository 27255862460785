import "../styles/globals.css";

import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  type LocalizationProviderProps
} from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import SystemAlertDialog from "components/dialog/SystemAlertDialog";
import SystemConfirmDialog from "components/dialog/SystemConfirmDialog";
import SystemPromptDialog from "components/dialog/SystemPromptDialog";
import { settings } from "configs/settings";
import mdTheme from "configs/theme";
import { nextDatadogRumInit } from "datadog";
import AlertSnackbar from "layout/AlertSnackbar";
import "dayjs/locale/ko";
import { LayoutContainer } from "layout/LayoutContainer";
import SuccessSnackbar from "layout/SuccessSnackbar";
import type { AppProps } from "next/app";
import Head from "next/head";
import AuthProvider from "provider/AuthProvider";
import { CookiesProvider } from "react-cookie";
import { RecoilRoot } from "recoil";
import "@fortawesome/fontawesome-free/js/all.js";
import { SystemDialogProvider } from "shared-ui";
import appPackage from "../../package.json";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      staleTime: 1000
    },
    mutations: {
      useErrorBoundary: true
    }
  }
});

// datadog setting
nextDatadogRumInit(
  process.env.NEXT_PUBLIC_MODE === "development"
    ? {
        excludeAuthError: true,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        packageVersion: appPackage.version
      }
    : {
        excludeAuthError: true,
        sessionSampleRate: 3,
        sessionReplaySampleRate: 20,
        packageVersion: appPackage.version
      }
);

const mode = settings.MODE;

function MyApp({ Component, pageProps }: AppProps) {
  const title =
    mode === "development" ? "[DEV] 일레클 관제시스템" : "일레클 관제시스템";

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
      </Head>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={mdTheme}>
            <LocalizationProvider
              dateAdapter={
                AdapterDayjs as unknown as LocalizationProviderProps["dateAdapter"]
              }
              adapterLocale="ko"
            >
              <SystemDialogProvider>
                <RecoilRoot>
                  <AuthProvider>
                    <LayoutContainer
                      Component={Component}
                      pageProps={pageProps}
                    >
                      <SystemConfirmDialog />
                      <SystemPromptDialog />
                      <SystemAlertDialog />
                      <AlertSnackbar />
                      <SuccessSnackbar />
                    </LayoutContainer>
                  </AuthProvider>
                </RecoilRoot>
              </SystemDialogProvider>
            </LocalizationProvider>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </CookiesProvider>
    </>
  );
}

export default MyApp;
