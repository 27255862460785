import { useMutation } from "hooks/useMutation";
import { signout } from "network/account/accountApi";
import type { MutationOptions } from "types/mutation.type";

const useAccountSingoutCreate = (options: MutationOptions = {}) => {
  const {
    mutate: signOutAccount,
    isLoading: signOutAccountLoading,
    error: signOutAccountError
  } = useMutation(() => signout(), {
    ...options
  });

  return {
    signOutAccount,
    signOutAccountLoading,
    signOutAccountError
  };
};

export default useAccountSingoutCreate;
