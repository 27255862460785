import useAppLayoutState from "hooks/recoilState/useAppLayoutState";
import Layout from "layout";
import { useRouter } from "next/router";
import { AsyncErrorBoundary } from "provider/AsyncErrorBoundary";
import { useEffect } from "react";

export const LayoutContainer = ({ Component, pageProps, children }) => {
  const router = useRouter();
  const pathWithoutLayout = ["/user/message", "/login"];
  const [appLayoutState, setAppLayoutState] = useAppLayoutState();

  useEffect(() => {
    if (router.isReady && pathWithoutLayout.includes(router.pathname)) {
      setAppLayoutState({
        isLayoutHidden: true
      });
    }
  }, [router.isReady]);

  return (
    <>
      {appLayoutState.isLayoutHidden ? (
        <AsyncErrorBoundary>
          <Component {...pageProps} />
        </AsyncErrorBoundary>
      ) : (
        <Layout>
          <AsyncErrorBoundary>
            <Component {...pageProps} />
          </AsyncErrorBoundary>
        </Layout>
      )}
      {children}
    </>
  );
};
