import { BaseConfirmDialog, useSystemDialog } from "shared-ui";

export default function SystemConfirmDialog() {
  const { title, content, open, onCancel, onConfirm } = useSystemDialog();

  return (
    <BaseConfirmDialog
      open={open === "confirm"}
      title={title}
      content={content}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
}
