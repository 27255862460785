import type { AxiosRequestConfig } from "axios";
import httpClient from "./httpClient";

export function getFetcher<T = unknown>(
  url: string,
  config?: AxiosRequestConfig<any>
) {
  return httpClient.get<T>(url, config).then((res) => res.data);
}

export function putFetcher<T = unknown>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig<any>
) {
  return httpClient.put<T>(url, data, config).then((res) => res.data);
}

export function patchFetcher<T = unknown>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig<any>
) {
  return httpClient.patch<T>(url, data, config).then((res) => res.data);
}

export function postFetcher<T = unknown>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig<any>
) {
  return httpClient.post<T>(url, data, config).then((res) => res.data);
}

export function deleteFetcher<T>(
  url: string,
  config?: AxiosRequestConfig<any>
) {
  return httpClient.delete<T>(url, config).then((res) => res.data);
}
