import { accountQueryKeys } from "constants/account.constant";
import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "hooks/useMutation";
import { signin } from "network/account/accountApi";
import type { SigninBody } from "types/account.type";
import type { MutationOptions } from "types/mutation.type";

const useAccountSinginCreate = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const onSuccess = options?.onSuccess;

  const {
    mutate: signinAccount,
    isLoading: signinAccountLoading,
    error: signinAccountError
  } = useMutation((body: SigninBody) => signin(body), {
    ...options,
    onSuccess: (data: any, variables: void, context: unknown) => {
      onSuccess && onSuccess(data, variables, context);
      queryClient.invalidateQueries(accountQueryKeys.accountAll);
    }
  });

  return {
    signinAccount,
    signinAccountLoading,
    signinAccountError
  };
};

export default useAccountSinginCreate;
