import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MenuListItems from "layout/drawer/MenuListItems";
import LogoutButton from "layout/LogoutButton";
import UserInfoItem from "layout/UserInfoItem";
import { useRouter } from "next/router";
import {
  type OpMenus,
  getAllowedMenus,
  getCanAccessPage,
  opMenus
} from "op-business";
import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { AccountState } from "store/accountRecoil";

function MenuList() {
  const account = useRecoilValue(AccountState);

  const menus = useMemo<OpMenus>(() => {
    if (!account) return [];
    return getAllowedMenus(account?.role_slug, menus);
  }, [opMenus, account?.level]);

  const router = useRouter();

  useEffect(() => {
    const canAccessPage = getCanAccessPage(router.pathname, menus);
    if (!canAccessPage) {
      router.replace("/404");
    }
  }, [router.pathname]);

  return (
    <List>
      <MenuListItems menus={menus} />
      <Divider
        sx={{
          paddingY: 1
        }}
      />
      <Box sx={{ paddingTop: 2 }}>
        <UserInfoItem />
        <LogoutButton />
      </Box>
    </List>
  );
}

export default MenuList;
