import Snackbar from "@mui/material/Snackbar";
import axios from "configs/axios";
import useErrorHandler from "hooks/useErrorHandler";
import { useMemo } from "react";
import { useAlertSnackbar } from "./hooks/useAlertSnackbar";

function AlertSnackbar() {
  const { isAlertSnackbarOpen, handleClose, alertMessage } = useAlertSnackbar();
  const { alertErrorMessage } = useErrorHandler();

  useMemo(() => {
    axios.interceptors.response.use((response) => {
      return response;
    }, alertErrorMessage);
  }, []);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      color="primary"
      open={isAlertSnackbarOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      message={alertMessage}
      ContentProps={{ sx: { background: "#ff2100" } }}
      sx={{ textAlign: "right" }}
    />
  );
}
export default AlertSnackbar;
