import {
  type UseMutationResult,
  useMutation as useMutationOrigin
} from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { useSystemDialog } from "shared-ui";
import type { MutationOptions } from "types/mutation.type";
import useErrorHandler from "./useErrorHandler";

export declare type QueryKey = readonly unknown[];

export function useMutation<TResult extends Record<string, any>>(
  mutationFn: (parmas?: any) => Promise<TResult>,
  options: MutationOptions<TResult> = {}
): UseMutationResult<TResult, AxiosError> {
  const { onError } = options;
  const { errorHandler } = useErrorHandler();
  const { systemAlert } = useSystemDialog();

  const handleSuccess = async (data, varaiables, context) => {
    const onSuccess = options?.onSuccess;
    const successAlertMessage = options?.successAlertMessage;
    const hasSuccessAlertMessage =
      "message" in (data || {}) || successAlertMessage;

    if (hasSuccessAlertMessage) {
      const message = successAlertMessage || data?.message;
      await systemAlert(message, "");
    }
    onSuccess && onSuccess(data, varaiables, context);
  };

  return useMutationOrigin<TResult, AxiosError>({
    useErrorBoundary: false,
    mutationFn,
    ...options,
    onSuccess: handleSuccess,
    onError: (err) => errorHandler(err, onError)
  });
}
