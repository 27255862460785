import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, {
  type AppBarProps as MuiAppBarProps
} from "@mui/material/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsFetching } from "@tanstack/react-query";
import type { ToolBarProps } from "types/layout.type";

function AppBar({ handleDrawer, open, drawerWidth }: ToolBarProps) {
  const isMobileSize = useMediaQuery(useTheme().breakpoints.down("sm"));
  const isIconHidden = open && isMobileSize;
  const isFetching = useIsFetching();

  return (
    <ToolBarContainer position="fixed" open={open} drawerWidth={drawerWidth}>
      <Toolbar variant="dense">
        {!isIconHidden && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
        )}
        {isFetching ? (
          <CircularProgress
            sx={{ color: "#fff", margin: "auto 0 auto auto" }}
            size={25}
          />
        ) : null}
      </Toolbar>
    </ToolBarContainer>
  );
}

export default AppBar;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerWidth: number;
}

const ToolBarContainer = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth"
})<AppBarProps>(({ theme, open, drawerWidth }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));
