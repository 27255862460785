import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

export const alertSnackbarState = atom({
  default: false,
  key: "AlertSnackbarState"
});

export const alertMessageState = atom({
  default: "",
  key: "AlertMessageState"
});

export function useAlertSnackbar() {
  const [isAlertSnackbarOpen, setIsAlertSnackbarOpen] =
    useRecoilState(alertSnackbarState);
  const [alertMessage, setAlertMessage] = useRecoilState(alertMessageState);
  const handleClose = () => {
    setIsAlertSnackbarOpen(false);
  };
  const openAlertSnackbar = useCallback((message: string) => {
    setAlertMessage(message);
    setIsAlertSnackbarOpen(true);
  }, []);

  return {
    isAlertSnackbarOpen,
    openAlertSnackbar,
    handleClose,
    alertMessage,
    setAlertMessage
  };
}
